import Link from 'next/link';
import Button from '@material-ui/core/Button';

export default function Custom404() {
  return (
    <main>
      <center>
        <h1>404 お探しのページが見つかりません。</h1>
        <p>このページはすでに削除されているか、URLが間違っている可能性があります。</p>

        <Link href="/">
          <Button color="warning" variant="outlined">
            トップへ戻る
          </Button>
        </Link>

        <br />
        <br />

        <iframe
          src="https://giphy.com/embed/l2JehQ2GitHGdVG9y"
          width="480"
          height="362"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </center>
    </main>
  );
}
